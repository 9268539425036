<template lang="pug">
.pi-render-course-block.pi-view(v-if="courseBlock" :class="piRenderCourseBlockClass")
  .pi-bricks-render.bg-white
    .pi-render.unselectable-children
      .pi-lateral.pi-toolbar.relative(v-if="showToolBar")
        .width-100.mobile-display-none(style="width: var(--lateral-width)")
        .scroll-container
          vue-scroll(
            ref="sidebar-scroll"
          )
            //- prévient de la marge qui fausse les calculs du scroll
            .mobile-display-none(style="height:1px;")
            slot(name="toolbar")
            .hints.entry.desktop-pointer-none(v-if="showHintsEntry" @click="showHintsModalVisibility")
              .entry__title
                | Indices
              template(v-for="tag in ['div.entry__content.mobile-display-none.desktop-pointer-none', 'el-dialog.hints.desktop-pointer-none']")
                component(
                  append-to-body
                  title="Indices"
                  :is="tag.split('.')[0]"
                  :class="tag.split('.').slice(1)"
                  :visible.sync='hintsModalVisibility'
                )
                  .text-align-center.medium-margin-y
                    el-button.desktop-pointer-all.btn__square.btn__square-small.btn__square.bg-primary.has-hint(
                      @click.stop="check(nextMalusCost)"
                      v-if="showHintsButton"
                    )

                      i.el-icon-plus.vertical-align-sub.no-padding_before
                      span Débloquer un indice

                  .reverse.width-full
                    .hint(v-for='(hint, index) in hintsToDisplay')
                      .circle.absolute
                        span {{ index + 1 }}
                      p {{ hint }}

            //- assure une marge confortable en pied de la zone
            .mobile-display-none.large-padding-bottom.large-padding-top

      .pi-brick(:class="brickClasses")
        .brick-part-wrapper
          vue-scroll(
            ref="content-scroll"
            @handle-scroll="handleContentScroll"
          )
            .brick-part-content
              .brick-title.brick-part()
                h2(v-if='headerIsvisible() && courseBlock.title') {{ courseBlock.title }}
                .flex-grow-1(v-else)
                a.closeBrick.flex-center.justify-content-center(v-if="(isPiCorrection || !isCorrectionMode)", @click="backToHome")
                  i.el-icon-pi-close
              .brick-part(:class="`brick-${brickType}`")
                //- TINCAN
                template(v-if="isTincan")
                  iframe.visible-coverage(
                    allowfullscreen
                    style='overflow:hidden;margin-bottom:-5px;',
                    scrolling="no"
                    :src="tincanUrl"
                    frameborder="0"
                    referrerpolicy="no-referrer"
                    width="100%"
                    height="100%"
                  )

                //- EXERCICE
                //- STATEMENT
                .statement.courseContent.wysiwyg-style(
                  v-if="brickType === 'exercice'",
                  v-html="enonceParsed",
                  :class="`column-${statementSettings.column}`"
                  )
                //- INTERATIVE IMAGE
                pi-interactive-image(
                  v-if="brickType === 'exercice' && isNotEmptyArray(interactiveImage.blocks)",
                  :interactiveImage="interactiveImage",
                  :sections="sections"
                  )
                //- TABLE
                pi-wysiwyg-table(
                  v-if="brickType === 'exercice' && isNotEmptyArray(interactiveTable)",
                  :table="interactiveTable",
                  :sections="sections",
                  :dataset="dataset",
                  :datasetsAlreadyComputed="datasetsAlreadyComputed"
                  )
                .def-container
                  template(v-for="term in courseBlock.terms")
                    span.def.hidden(:data-id="term.id")
                      | {{ term.definition}}
                //- SECTIONS
                //- EXERCICE || SLIDE
                .section(
                  v-for="(section, nbSection) in sections",
                  :key="nbSection",
                  v-if="section.rows.length > 0")
                  h3(v-if="section.title") {{ section.title }}
                  p(v-if="section.description") {{ section.description }}
                  template(v-for="(row, index) in section.rows")
                    component.pi-content(
                      :ref="`sections[${nbSection}].rows[${index}]`"
                      :is="`pi-${row.type}`",
                      :class="getClass(row, nbSection, index)",
                      :data-value="`sections[${nbSection}].rows[${index}]`",
                      :row="row",
                      :nbSection="nbSection",
                      :rowIndex="index",
                      :rowCount="section.rows.length",
                      :dataset="dataset",
                      :course='course',
                      :reponse="answers[`sections[${nbSection}].rows[${index}]`]",
                      :wordPicked.sync="wordPicked",
                      :courseCategoryId="courseCategoryId",
                      :sectionName="sectionName",
                      :isCorrectionMode="isCorrectionMode && correctAnswer[`sections[${nbSection}].rows[${index}]`]",
                      :correctAnswer="correctAnswer[`sections[${nbSection}].rows[${index}]`]",
                      :datasetsAlreadyComputed="datasetsAlreadyComputed")

    .brick-footer(v-if="courseBlock && brickType === 'exercice'")
      el-col.brick-navigation(
        v-if="!isCorrectionMode"
        :span="24",
        )
        div
          portal-target.display-inline-block(data-name="lesson-page-prev" name="lesson-page-prev" multiple)
        .flex-grow-1.flex-center.justify-content-center
          main-sound-player.width-100
        div
          el-button.btn__square.bg-primary.mobile-without-text(
            v-if="canShowFullscreenButton"
            @click="openIframeInFullscreen"
          )
            | {{ $t('actions.fullscreen.action') }}
            i.el-icon-pi-fullscreen
          el-button.btn__square.bg-primary.without-text.fullscreenPolyfillButton(
            v-else-if="canShowFullscreenPolyfillButton"
            @click="toggleIframeInFullscreenPolyfill"
          )
            i.el-icon-pi-fullscreen
            i.el-icon-pi-fullscreen-off

          portal-target.display-inline-block.medium-margin-right(data-name="lesson-page-next" name="lesson-page-next" multiple  @change="nextPortalArea")

          el-button.btn__square(
            v-if="!hasAlternativeNextButton"
            :loading="isNexting"
            :class="(!requireFullSeen || fullSeen) ? 'bg-primary' : 'bg-secondary'"
            @click="next"
          )
            | {{ nextActionLabel }}
            i.el-icon-pi-fleche-bouton

    .brick-footer(
      v-else-if="!isCorrectionMode && (canShowFullscreenButton || showNextButton || settings.backToDashBoard || canShowFullscreenPolyfillButton)"
    )
      el-col.brick-navigation(
        :class="navigationClass"
        v-if="!isCorrectionMode"
        :span="24"
        )
        div
          portal-target.display-inline-block(data-name="lesson-page-prev" name="lesson-page-prev" multiple)
        .flex-grow-1
          main-sound-player
        div

          el-button.btn__square.bg-primary.mobile-without-text(
            v-if="canShowFullscreenButton"
            @click="openIframeInFullscreen"
          )
            span.mobile-display-none {{ $t('actions.fullscreen.action') }}
            i.el-icon-pi-fullscreen

          el-button.btn__square.bg-primary.without-text.fullscreenPolyfillButton(
            v-else-if="canShowFullscreenPolyfillButton"
            @click="toggleIframeInFullscreenPolyfill"
          )
            i.el-icon-pi-fullscreen
            i.el-icon-pi-fullscreen-off

          portal-target.display-inline-block.medium-margin-right(data-name="lesson-page-next" name="lesson-page-next" multiple  @change="nextPortalArea")

          el-button.btn__square(
            v-if="!hasAlternativeNextButton && showNextButton"
            :loading="isNexting"
            :class="(!requireFullSeen || fullSeen) ? 'bg-primary' : 'bg-secondary'"
            @click="next"
          )
            | {{ nextActionLabel }}
            i.el-icon-pi-fleche-bouton
          el-button.btn__square.bg-tertiary(
            :loading="isNexting"
            v-if="settings.backToDashBoard && !isTincan"
            @click="backToDashBoard"
          )
            span
              span.desktop-display-none Tableau de bord
              span.mobile-display-none Retour au tableau de bord
            i.el-icon-pi-fleche-bouton

  .pi-feedbacks(v-if='feedback'
    :class="containsHtmlTags(feedback.message) ? 'with-wysiwyg' : ''"
  )
    el-dialog(
      :class='feedback.type'
      :title="$t(`components.feedbacks.${feedback.type}.title`)"
      :visible="feedback.visible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    )
      p(v-if='!containsHtmlTags(feedback.message)') {{ parseShortCodes(feedback.message ) }}
      .wysiwyg-style(v-else, v-html="setImageInHtml(parseShortCodes(feedback.message ))")
      template(v-if="answerFeedbacks.length > 0 && feedbacksClicks >= (feedbacks.length)")
        template(v-for="answerFeedback in answerFeedbacks")
          hr(v-if="feedback.message")
          h3(v-if='!containsHtmlTags(answerFeedback.title)') {{ answerFeedback.title }}
          h3.wysiwyg-style(v-else, v-html="setImageInHtml(parseShortCodes(answerFeedback.title ))")
          p(v-if='!containsHtmlTags(answerFeedback.message)') {{ parseShortCodes(answerFeedback.message ) }}
          .wysiwyg-style(v-else, v-html="setImageInHtml(parseShortCodes(answerFeedback.message ))")
      .buttons
        el-button.btn__square.bg-primary(:loading="isNexting" @click="setNextFeedback")
          | {{ $t('actions.continue.action') }}
          i.el-icon-pi-fleche-bouton

    el-dialog.incorrect(
      :visible="noAnswer.seeModal"
      title="Aucune réponse saise"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    )
      p Vous n'avez donné aucune réponse
      .buttons
        el-button.btn__square.bg-tertiary(@click="noAnswer.seeModal = false")
          | {{ $t('actions.stay.action') }}
        el-button.btn__square.bg-primary(@click="next" :loading="isNexting")
          | {{ $t('actions.continue.still') }}
          i.el-icon-pi-fleche-bouton
  .temoin.absolute.visible-coverage(style="visibility:hidden;top:0;left:0;z-index:-100")
</template>

<script>
import store from '@/api/index.js'
import utils from '@/helpers/utils.js'
import regexp from '@/helpers/regexp.js'
import {
  cloneDeep,
  compact,
  each,
  endsWith,
  filter,
  flatten,
  get,
  hasIn,
  includes,
  isEmpty,
  isNil,
  isNull,
  isString,
  isUndefined,
  map,
  snakeCase,
  sortBy,
  uniqBy,
} from 'lodash'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'

import PiWysiwygTable from './content/wysiwyg-table/PiWysiwygTable.vue'
import PiInteractiveImage from './content/interactive-image/PiInteractiveImage.vue'
import MainSoundPlayer from './Tools/MainSoundPlayer.vue'

import PiMultipleChoice from './content/sections/Pi-Multiple-Choice.vue'
import PiCombinedChoice from './content/sections/Pi-Combined-Choice.vue'
import PiFreeAnswer from './content/sections/Pi-Free-Answer.vue'
import PiDragNDrop from './content/sections/Pi-Drag-N-Drop.vue'
import PiPickWords from './content/sections/Pi-Pick-Words.vue'
import PiImageCliquable from './content/sections/Pi-Image-Cliquable.vue'

import PiVideoExternal from './content/sections/Pi-Video-External.vue'
import PiMediaInternal from './content/sections/Pi-Media-Internal.vue'
import PiMediaTincan from './content/sections/Pi-Media-Tincan.vue'
import PiWysiwyg from './content/sections/Pi-Wysiwyg.vue'
import PiMediasByMail from './content/sections/Pi-Medias-By-Mail.vue'

import PiWidgetCourseIcon from './content/sections/Pi-Widget-Course-Icon.vue'
import PiWidgetXpEarned from './content/sections/Pi-Widget-Xp-Earned.vue'
import PiWidgetPiXpEarned from './content/sections/Pi-Widget-Pi-Xp-Earned.vue'
import PiWidgetJobXpEarned from './content/sections/Pi-Widget-Job-Xp-Earned.vue'
import PiWidgetCompetencyXpEarned from './content/sections/Pi-Widget-Competency-Xp-Earned.vue'
import PiWidgetCourseCompetencyList from './content/sections/Pi-Widget-Course-Competency-List.vue'
import PiWidgetCourseTitle from './content/sections/Pi-Widget-Course-Title.vue'
import PiWidgetSectionTitle from './content/sections/Pi-Widget-Section-Title.vue'

import RenderMixin from '@/mixins/Render.js'
import UtilMixin from '@/mixins/Utils.js'

import { MIME_TYPE_PDF } from '@/api/models/AppFile.js'

const $ = require('zepto-modules')
window.$ = $

export default {
  name: 'pi-render-course-block',
  components: {
    MainSoundPlayer,
    PiPop,
    PiWysiwygTable,
    PiInteractiveImage,
    PiMultipleChoice,
    PiCombinedChoice,
    PiFreeAnswer,
    PiDragNDrop,
    PiPickWords,
    PiVideoExternal,
    PiMediaInternal,
    PiWysiwyg,
    PiMediasByMail,
    PiWidgetCourseIcon,
    PiWidgetXpEarned,
    PiWidgetPiXpEarned,
    PiWidgetJobXpEarned,
    PiWidgetCompetencyXpEarned,
    PiWidgetCourseCompetencyList,
    PiWidgetCourseTitle,
    PiWidgetSectionTitle,
    PiImageCliquable,
    PiMediaTincan,
  },
  mixins: [RenderMixin, UtilMixin],
  props: ['id', 'content', 'courseId', 'sectionName', 'page', 'isCorrectionMode', 'previewMode'],
  data() {
    return {
      handleContentScrollInterval: null,
      fullSeen: false,
      requireFullSeen: false,
      hintsModalVisibility: false,
      hasAlternativeNextButton: false,
      tincanFromExercices: null,
      isNexting: false,
      isBackToHome: false,
      interactiveImage: {},
      interactiveTable: {},
      showTincan: false,
      answers: {},
      hintsClicks: 0,
      feedbacksClicks: 0,
      brick: {},
      enonceParsed: null,
      wordPicked: [],
      courseBlock: null,
      item: null,
      responseCreated: false,
      feedback: {
        visible: false,
        message: 'body',
        type: 'default', // [default, incorrect, correct, partialy-correct]
      },
      noAnswer: {
        seeModal: false,
        continue: false,
      },
    }
  },
  computed: {
    brickClasses() {
      return {
        [`brick-${this.brickType}`]: true,
      }
    },
    showToolBar() {
      return (
        (!!this.$slots.toolbar && this.$slots.toolbar.filter((el) => !el.isComment).length > 0) || this.showHintsEntry
      )
    },
    answerFeedbacks() {
      const messagesToDisplay = []
      const sections = this.isNodeMode ? this.sections : this.page?.results?.sections || []

      try {
        sections.forEach((section, sectionIndex) => {
          ;(section?.rows || []).forEach((row, rowIndex) => {
            ;(row?.feedbacks || []).forEach((feedback) => {
              const answerIndex = Number(feedback?.conditions?.[0]?.answers?.[0])
              if (isNaN(answerIndex)) return

              const title = this.sections?.[sectionIndex]?.rows?.[rowIndex]?.choices?.[answerIndex].value
              if (!isString(title)) return

              const { message } = feedback

              messagesToDisplay.push({
                title,
                message,
              })
            })
          })
        })
      } catch (error) {
        console.error(error)
      }
      return messagesToDisplay
    },
    piRenderCourseBlockClass() {
      return {
        'hasnt-toolbar': !this.showToolBar,
      }
    },
    tincanUrl() {
      if (!this.isTincan) {
        return ''
      }
      let url = ''
      try {
        url = new URL(this.tincan.url)
      } catch (error) {
        console.error("impossible de lire l'url", this.tincan.url)
        return ''
      }
      if (this.contextLrsHost) {
        url.searchParams.append('endpoint', this.contextLrsHost)
      }
      if (this.contextLrsBasicAuth) {
        url.searchParams.append('auth', `Basic ${this.contextLrsBasicAuth}`)
      }
      const user = store.get('AppUser', this.getCurrentUser.id)
      const actor = {
        name: [user.tincanIdentifier],
        account: {
          homePage: this.contextFrontoffice,
          name: user.id,
        },
      }
      url.searchParams.append('actor', JSON.stringify(actor))
      url.searchParams.append('registration', get(this, 'page.id', '00000000-0000-4000-aaaa-aaaaaaaaaaaa'))
      return url.href
    },
    isTincan() {
      return this.brickType === 'tincan'
    },
    isPdf() {
      let rowCount = 0
      let pdfCount = 0
      this.sections.forEach((s) => {
        rowCount += s.rows.length
        s.rows.forEach((r) => {
          if (r.type === 'media-internal' && r.appFile?.id && this.$store.getters.DSAppFile?.[r.appFile.id]) {
            const type = this.$store.getters.DSAppFile?.[r.appFile.id].type
            if (MIME_TYPE_PDF.includes(type)) {
              pdfCount++
            }
          }
        })
      })
      return rowCount === 1 && pdfCount === 1
    },
    showNextButton() {
      if (this.settings.backToDashBoard && this.brickType !== 'tincan') {
        return false
      }
      if (get(this.settings, 'hideNextButton', false)) {
        return false
      }
      return true
    },
    navigationClass() {
      return {
        'text-align-center': this.isTincan,
      }
    },
    nextActionLabel() {
      if (this.isTincan) {
        return this.$t('actions.endModule.action')
      }
      return this.$t('actions.next.action')
    },
    canShowFullscreenButton() {
      return Boolean(this.isTincan && this.hasFullscreenCapability)
    },
    canShowFullscreenPolyfillButton() {
      return (this.isTincan && !this.hasFullscreenCapability) || this.isPdf
    },
    nextMalus() {
      if (this.isPageMode) {
        this.$store.getters.DSLessonPage[this.page.id]
        const malus = get(this, 'page.results.hints.nextMalus')
        return malus
      }

      return undefined
    },
    nextMalusClass() {
      if (isString(this.nextMalus)) {
        return 'hint-with-cost'
      }
      if (isNull(this.nextMalus)) {
        return 'free-hint'
      }
      return ''
    },
    hasNextMalusCost() {
      return !isUndefined(this.nextMalusCost)
    },
    nextMalusCost() {
      if (this.nextMalus) {
        return this.nextMalus
      }

      return undefined
    },
    hintsToDisplay() {
      if (this.isNodeMode) {
        const hints = []
        const hintsLength = get(this.courseBlock, 'settings.hints', []).length
        for (let i = 0; i < this.hintsClicks && i < hintsLength; i++) {
          hints.push(this.courseBlock.settings.hints[i].message)
        }
        return hints
      } else if (this.isPageMode) {
        return get(this, 'page.results.hints.messages', [])
      }

      return undefined
    },
    inputs() {
      const out = {}

      each(this.answers, (value, index) => {
        if (hasIn(value, 'path') && hasIn(value, 'userAnswer')) {
          out[value.path] = value.userAnswer
        }
      })
      return out
    },
    _interactiveImage() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.interactiveImage', {})
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.interactiveImage', {})
      }

      return undefined
    },
    _interactiveTable() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.table', {})
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.table', {})
      }

      return undefined
    },
    statementSettings() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.statement.settings', {})
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.statement.settings', {})
      }

      return undefined
    },
    statement() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.statement.html', '')
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.statement.html', '')
      }

      return undefined
    },
    tincan() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.tincan', this.tincanFromExercices || {})
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.tincan', this.tincanFromExercices || {})
      }

      return undefined
    },
    dataset() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.datasets', [])
      } else if (this.isPageMode) {
        return [get(this, 'courseBlock.dataset', {})]
      }

      return undefined
    },
    courseCategoryId() {
      return get(this.course, 'categoryId', null)
    },
    sections() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.content.sections', [])
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.sections', [])
      }

      return undefined
    },
    settings() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.settings.template', {})
      } else if (this.isPageMode) {
        return get(this, 'courseBlock.settings.template', {})
      }

      return undefined
    },
    feedbacks() {
      if (this.isNodeMode) {
        return get(this, 'courseBlock.settings.feedbacks', [])
      } else if (this.isPageMode) {
        return get(this, 'page.results.feedbacks', [])
      }

      return undefined
    },
    datasetsAlreadyComputed() {
      const out = {}
      if (this.isNodeMode) {
        for (const key in get(this, 'courseBlock.content.datasetsComputed[0]', {})) {
          const expression = key
            .toString()
            .replace(/\\\\u002e/g, '.')
            .replace(/\\u002e/g, '.')
          this.$set(out, expression, this.courseBlock.content.datasetsComputed[0][key])
        }
      } else if (this.isPageMode) {
        for (const keyy in get(this, 'page.sheet.datasetComputed', {})) {
          const expressionn = keyy
            .toString()
            .replace(/\\\\u002e/g, '.')
            .replace(/\\u002e/g, '.')
          this.$set(out, expressionn, this.page.sheet.datasetComputed[keyy])
        }
      }
      return out
    },
    isNodeMode() {
      return !isUndefined(this.id)
    },
    isPageMode() {
      return !isUndefined(this.content)
    },
    showHintsEntry() {
      return this.showHints && (this.showHintsButton || (this.hintsToDisplay && this.hintsToDisplay.length > 0))
    },
    showHintsButton() {
      if (this.isNodeMode) {
        return (
          this.showHints &&
          !isEmpty(this.courseBlock?.settings?.hints) &&
          get(this.courseBlock, 'settings.hints.length', 0) > this.hintsClicks
        )
      } else if (this.isPageMode) {
        this.$store.getters.DSLessonPage
        return this.showHints && get(this.page, 'results.hints.remaining', 0) > 0
      }

      return undefined
    },
    showHints() {
      this.$store.getters.DSCourse
      this.$store.getters.DSCourseType
      return get(this.course, 'type.settings.hints', false)
    },
    hasClickWord() {
      const rows = JSON.search(this.sections, '*/*/rows[type="pick-words"]')
      return rows && rows.length > 0
    },
    brickType() {
      this.noAnswer.continue = this.courseBlock.type === 'slide' || this.courseBlock.type === 'tincan'
      return this.courseBlock.type
    },
    correctAnswer() {
      const correctAnswer = {}
      if (this.isNodeMode) {
        // nothing
      } else if (this.isPageMode && this.page.sheet && this.page.sheet.validators) {
        for (const key in this.page.sheet.validators) {
          const path = key
            .toString()
            .replace(/\\\\u002e/g, '.')
            .replace(/\\u002e/g, '.')
          this.$set(correctAnswer, path, {
            path: path,
            answer: this._get(this.page.sheet.validators, key),
            result: this._get(this.page.results.answerRatios, key),
            dataset: this._get(this.page.sheet, 'datasetComputed'),
          })
        }
      }
      return correctAnswer
    },
    isPiCorrection() {
      return this.isCorrectionMode && this.$route.params.id
    },
    hasFullscreenCapability() {
      const elem = window.document.body
      return (
        elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen
      )
    },
  },
  watch: {
    'course.category'() {
      if (get(this.course, 'category.backgroundImageId', false)) {
        this.setLayoutBackground(this.course.category.backgroundImageId)
      }
    },
    id() {
      this.process()
    },
    content() {
      this.process()
    },
  },
  mounted() {
    this.process()
    this.bindScrollFullSeen()
    this.addClassToBody()
    this.fullSeen = false
  },
  asyncComputed: {
    course: {
      get() {
        this.courseBlock // force le refresh
        // utilisation du cache, utile pour les preview
        const c = store.get('Course', this.courseId)
        if (c && c.type) {
          return c
        }
        // autres situations
        return store.find('Course', this.courseId, { params: { include: 'type,category' } })
      },
    },
  },
  beforeDestroy() {
    this.unbindScrollFullSeen()
    window.removeEventListener('message', this.tincanListeners, false)
    window.removeEventListener('keypress', this.onKeyEntrerPressed, false)
    this.closeIframeInFullscreenPolyfill()
    this.removeClassToBody()
  },
  created() {
    window.addEventListener('message', this.tincanListeners, false)
    window.addEventListener('keypress', this.onKeyEntrerPressed, false)
    window._vm_render_brick = this
  },
  methods: {
    bindScrollFullSeen() {
      this.unbindScrollFullSeen()
      this.handleContentScrollInterval = window.setInterval(this.handleContentScroll, 200)
    },
    unbindScrollFullSeen() {
      window.clearInterval(this.handleContentScrollInterval)
    },
    handleContentScroll() {
      if (!this.$refs['content-scroll'] || !this.$refs['content-scroll'].getScrollProcess) return

      if (this.$refs['content-scroll'].getScrollProcess().v > 0.9) {
        this.fullSeen = true
      }

      const contentScroll = this.$refs['content-scroll']?.$el
      const brickPartContent = document.querySelector('.brick-part-content')

      if (contentScroll && brickPartContent) {
        const contentScrollHeight = contentScroll.getBoundingClientRect().height
        const brickPartContentHeight = brickPartContent.getBoundingClientRect().height
        this.requireFullSeen = contentScrollHeight <= brickPartContentHeight - 10
      }
    },
    showHintsModalVisibility() {
      this.hintsModalVisibility = true
    },
    addClassToBody() {
      document.documentElement.classList.add('mobile-overflow-hidden')
    },
    removeClassToBody() {
      document.documentElement.classList.remove('mobile-overflow-hidden')
    },
    nextPortalArea(hasContent) {
      this.hasAlternativeNextButton = hasContent
    },
    closeIframeInFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      } catch (error) {
        console.error(error)
      }
    },
    openIframeInFullscreen() {
      const elem = this.$el.querySelector('iframe')
      if (!elem) {
        return
      }
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    toggleIframeInFullscreenPolyfill() {
      document.querySelector('html').classList.toggle('fullscreenPolyfill')
    },
    closeIframeInFullscreenPolyfill() {
      document.querySelector('html').classList.remove('fullscreenPolyfill')
    },
    updateTitle() {
      document.title = compact([this.sectionName, this.courseBlock.title]).join(' - ')
    },
    openBurgerMenu() {
      this.$emit('openCourseTree')
    },
    setTincanVisible() {
      this.showTincan = true
    },
    setResponse(section, row) {
      const path = `sections[${section}].rows[${row}]`
      const pathFromApi = path.replace(/\./g, '\\\\u002e')
      const ex = get(this, path)
      let userAnswer
      if (!includes(this.globals.EXERCICES, get(this, `sections[${section}].rows[${row}].type`))) {
        return
      }
      if (hasIn(this.answers, path)) {
        return this.answers[path]
      } else if (hasIn(this.page, `inputs.answers['${pathFromApi}']`)) {
        userAnswer = get(this.page, `inputs.answers['${pathFromApi}']`)
        if (ex.type === 'pick-words') {
          this.wordPicked = this.wordPicked.concat(userAnswer)
        }
      } else {
        switch (ex.type) {
          case 'image-cliquable':
          case 'multiple-choice':
            userAnswer = []
            break
          case 'combined-choice':
            userAnswer = null
            break
          case 'free-answer':
            userAnswer = ''
            break
          case 'drag-n-drop':
            userAnswer = []
            for (let j = 0; j < ex.targets.length; j++) {
              userAnswer.push(null)
            }
            break
          case 'pick-words':
            userAnswer = []
            break
          default:
            userAnswer = null
        }
      }
      this.$set(this.answers, path, { userAnswer, path })
      return this.answers[path]
    },
    isIntro() {
      return this.settings.backToDashBoard ? 'intro' : 'notIntro'
    },
    headerIsvisible() {
      if (this.isTincan) {
        return false
      }
      if (this.settings.hideMainTitle === true) {
        return false
      }
      return true
    },
    check(nextMalusCost) {
      if (nextMalusCost) {
        const message = `<p>En débloquant cet indice, votre score se verra réduit de <strong>${nextMalusCost}</strong>.</p>`
        this.$confirm(this.$html(message), 'Êtes-vous sûr ?', {
          confirmButtonText: "Débloquer l'indice",
          cancelButtonText: 'Annuler',
        }).then(() => {
          this.hintsClicks++
          this.$emit('check')
        })
      } else {
        this.hintsClicks++
        this.$emit('check')
      }
    },
    previous() {
      this.$emit('previous', this.inputs)
    },
    backToDashBoard() {
      if (this.isTincan) {
        // pour le tincan, on quitte, mais on ne sauvegarde pas.
        this.backToHome({ clear: false })
        return
      }
      this.isBackToHome = true
      return this.next()
    },
    onKeyEntrerPressed(event) {
      if (event.key === 'Enter' && !this.isTincan) {
        this.next()
      }
    },
    next() {
      if (this.isNodeMode) {
        this.setNextFeedback()
      } else if (this.isPageMode) {
        this.isNexting = true
        if (this.noAnswer.seeModal) {
          this.noAnswer.continue = true
        }
        const verifInputs = flatten(
          map(this.inputs, (input) => {
            return filter([].concat(input), (value) => {
              return value !== '' && !isNil(value)
            })
          }),
        )
        if (verifInputs.length === 0 && !this.noAnswer.continue) {
          this.noAnswer.seeModal = true
          this.isNexting = false
        } else {
          let patch
          if (this.isTincan || this.isSlide) {
            patch = this.page.lesson.emptyPatchCurrentPage(this.page.id, this.inputs)
          } else {
            patch = this.page.lesson.patchCurrentPage(this.page.id, this.inputs)
          }

          patch
            .then(() => {
              let skipFeedbacks = false
              this.noAnswer.seeModal = false
              if (verifInputs.length === 0 && this.noAnswer.continue) {
                skipFeedbacks = true
              }
              return this.setNextFeedback(skipFeedbacks)
            })
            .catch((err) => {
              this.isNexting = false
              if (get(err, 'response.data.error')) {
                const errorCode = get(err, 'response.data.code', 'UNDEFINED')
                this.$alert(this.$t(`errors.${errorCode}.message`), this.$t(`errors.${errorCode}.title`), {
                  customClass: 'error',
                  type: 'error',
                  confirmButtonText: 'OK',
                  callback: () => this.backToHome({ clear: false }),
                })
              }
            })
        }
      }
    },
    setNextFeedback(skipFeedbacks = false) {
      console.log('SET NEXT FEEDBACK')
      if (
        skipFeedbacks === true ||
        (this.feedbacksClicks >= this.feedbacks.length && this.answerFeedbacks.length === 0) ||
        (this.feedbacksClicks >= Math.max(this.feedbacks.length, 1) && this.answerFeedbacks.length > 0) ||
        !get(this.course, 'type.settings.feedbacks', false)
      ) {
        if (this.isPageMode) {
          this.isNexting = true
        } else {
          this.feedbacksClicks = 0
        }
        this.noAnswer.continue = false
        this.noAnswer.seeModal = false
        this.feedback.visible = false
        console.log('BACK TO HOME', this.isBackToHome)
        this.$emit('next', this.inputs, false, this.isBackToHome)
      } else {
        this.isNexting = false
        this.feedback.visible = true
        const currentFeedback = this.feedbacks?.[this.feedbacksClicks]
        this.feedback.message = currentFeedback?.message || ''
        this.feedback.type = snakeCase(get(currentFeedback, 'conditions[0].preset', 'default'))
        this.$emit('showFeedbacks')
        this.feedbacksClicks++
      }
    },
    getCourseBlock() {
      if (this.id === 'id-block') {
        // PREVIEW
        const courseBlock = store.get('CourseBlock', this.id)
        store
          .findAll('CourseTerm', { filter: { _id: { $in: courseBlock.termIds ?? [] } } }, { force: true, raw: true })
          .then((response) => {
            courseBlock.terms = response.data
            this.getCourseBlockCallback(courseBlock)
          })
      } else if (this.id) {
        // CHARGEMENT D'UN NODE
        store
          .find('CourseBlock', this.id, { params: { include: 'terms' } })
          .then(this.getCourseBlockCallback)
          .catch((err) => {
            console.error('[ RENDER BLOCK ]', err)
          })
      } else if (this.isPageMode) {
        // LESSON MODE
        store
          .findAll('CourseTerm', { filter: { _id: { $in: this.content.termIds } } }, { force: true, raw: true })
          .then((response) => {
            this.content.terms = response.data
            this.getCourseBlockCallback(this.content)
          })
      } else {
        console.error('[ RENDER BLOCK ] CourseBlock invalide')
      }
    },
    getCourseBlockCallback(record) {
      this.$set(this, 'courseBlock', record)
      if (window) window.scroll(0, 0)
      if (this.courseBlock.type === 'tincan') {
        this.setTincanVisible()
      }
      this.getElements()
      this.$nextTick()
    },
    createReponseItem() {
      for (const s in this.sections) {
        const section = this.sections[s]
        for (const r in section.rows) {
          this.setResponse(s, r)
        }
      }
      this.responseCreated = true
    },
    process() {
      // la ligne suivante permet de reseter le composant .. très important
      Object.assign(this.$data, this.$options.data.call(this))
      this.closeIframeInFullscreenPolyfill()
      this.getCourseBlock()
    },
    getElements() {
      this.updateTitle()
      if (!this.responseCreated) {
        this.createReponseItem()
      }
      if (this.courseBlock.type === 'slide') {
        this.$nextTick(this.setDictionnary)
      } else if (this.courseBlock.type === 'exercice') {
        let html = this.statement
        // ShortChodes
        html = this.parseShortCodes(html)
        html = this.setImageInHtml(html)
        this.$set(this, 'enonceParsed', html)
        this.$set(this, 'interactiveImage', cloneDeep(this._interactiveImage))
        this.$set(this, 'interactiveTable', cloneDeep(this._interactiveTable))
        this.$nextTick(this.exerciceTransform)
      }
    },
    setImageInHtml(html = '') {
      const $html = document.createElement('div')
      $html.innerHTML = html

      const attachementsImages = $html.querySelectorAll('img[data-file-id]')

      if (attachementsImages.length) {
        for (const img of attachementsImages) {
          img.src = utils.getFilePath(img.attributes['data-file-id'].value)
        }
      }

      return $html.innerHTML
    },
    exerciceTransform() {
      if (this.responseCreated) {
        this.$nextTick(function () {
          this.setDictionnary()
          this.transformSpanCodes()
          this.rearrangePositions()
          if (this.isCorrectionMode) {
            this.$nextTick(function () {
              this.setCorrection()
            })
          }
        })
      }
    },
    getClass(row, nbSection, index) {
      const out = []
      out.push(`ql-${row.type}.sections-${nbSection}-rows-${index}`)
      if (includes(this.globals.EXERCICES, row.type)) {
        out.push('ex')
      }
      return out
    },
    validateRender() {
      this.$emit('validate', 'render')
    },
    rearrangePositions() {
      if (!this.$el) return
      const medias = this.$el.querySelectorAll('.pi-media-internal, .pi-video-external')

      for (const el of medias) {
        const row = get(this, el.dataset.value)

        if (get(row, 'settings.beforeStatement', false)) {
          const statementDiv = this.$el.querySelector('div.statement')
          statementDiv.parentNode.insertBefore(el, statementDiv)
        }
      }
    },
    setDictionnary() {
      const vm = this
      vm
      const { terms } = this.courseBlock
      if (isEmpty(terms)) {
        return
      }
      // il faut lister tous les mots à définir et avec une tolérance.
      let words = terms.map((term) => {
        return term.words.map((word) => {
          return { word, term, regexp: regexp.getGlossaireRegFromWord(word) }
        })
      })
      words = flatten(words)
      words = sortBy(words, (word) => {
        return -word.regexp.length
      })
      words = uniqBy(words, 'regexp')
      const $$el = $(this.$el)

      const $statement = $$el.find('div.statement')
      words.forEach((word) => {
        // STATEMENT
        const $s = $statement.find('p, strong, em, s, u, span[style]:not(.pi-exercice), td, th')
        const $t = $$el.find('.pi-wysiwyg-table').find('.text-cell .text')
        const $w = $$el.find('.pi-wysiwyg').find('p, strong, em, s, u')
        const $textNodes = $s.add($t).add($w)
        $textNodes
          .contents()
          .filter(function () {
            return this.nodeType === 3
          })
          .each((index, node) => {
            if (typeof node === 'object' && node.nodeValue && node.nodeValue !== ' ') {
              const reg = word.regexp
              const regexp = new RegExp(`(^|[^a-z0-9])(${reg})($|[^a-z0-9])`, 'gi')
              node.data = node.nodeValue.replace(regexp, `$1<span class="term" data-id="${word.term.id}">$2</span>$3`)
              $(node).replaceWith(node.data)
            }
          })
      }, this)
      $$el.find('.term[data-id]').on('mouseover', vm.getDef)
      $$el.find('.term[data-id]').on('mouseout', vm.hideDef)
    },
    getDef(ev) {
      const id = ev.target.getAttribute('data-id')
      const defs = this.$el.querySelectorAll(`span.def[data-id="${id}"]`)

      for (const def of defs) {
        def.style.top = `${ev.currentTarget.getBoundingClientRect().top + 30}px`
        def.style.left = `${ev.currentTarget.getBoundingClientRect().left + 30}px`
        def.classList.remove('hidden')
      }
    },
    hideDef(ev) {
      const id = ev.target.getAttribute('data-id')
      const defs = this.$el.querySelectorAll(`span.def[data-id="${id}"]`)

      for (const def of defs) {
        def.classList.add('hidden')
      }
    },
    transformSpanCodes() {
      const vm = this
      const $$el = $(this.$el)
      const $statement = $$el.find('div.statement')
      const updatePickWords = function () {
        vm.wordPicked = []
        $statement.find('.pick-words-selected').each((index, select) => {
          if (!includes(vm.wordPicked, select.innerText)) {
            // on ne met qu'une fois chaque mot
            vm.wordPicked.push(select.innerText)
          }
        })
        $$el
          .find('.pi-wysiwyg-table table')
          .children('tr')
          .each(function (rowIndex, row) {
            const $row = $(row)
            $row.children('td').each(function (colIndex, col) {
              if ($(this).hasClass('pick-words-selected')) {
                vm.wordPicked.push(`table:column-${colIndex}:line-${rowIndex}`)
              }
            })
          })
      }
      // TINCAN
      const tincanIcon = 'el-icon-fa-desktop'
      const tincanSelector = '.ql-media-tincan'
      const tincanWrapper = 'ql-media-tincan-wrapper'
      $$el
        .find(`img.pi-content${tincanSelector}`)
        .wrap(`<div class="img pi-content-wrapper ${tincanWrapper} ${tincanIcon}">`)
      $$el.find(`.pi-content${tincanSelector}:not(img)`).prepend(`<i class="${tincanIcon}"></i> `)

      $$el.find(tincanSelector).on('click', function () {
        const path = this.dataset.value
        const row = vm._get(vm, path)
        if (!row) {
          return
        }
        vm.tincanFromExercices = { url: row.settings.url }
        vm.setTincanVisible()
      })

      // SOUND
      const soundIcon = 'el-icon-fa-volume-up'
      const soundSelector = '.ql-media-internal'
      const soundWrapper = 'ql-media-internal-wrapper'
      $$el
        .find(`img.pi-content${soundSelector}`)
        .wrap(`<div class="img pi-content-wrapper ${soundWrapper} ${soundIcon}">`)
      $$el.find(`.pi-content${soundSelector}:not(img)`).prepend(`<i class="${soundIcon}"></i> `)
      function hideAllSoundPlayer(exclude) {
        $$el.find('.ql-media-internal').each(function () {
          const rowPath = this.dataset.value
          this.dataset.soundFileId = get(vm, `${rowPath}.appFile.id`) || null
          if (!rowPath && vm.$refs[rowPath]) {
            return
          }
          const componentSound = get(vm.$refs[rowPath], [0, '$children', 0])
          if (componentSound) {
            componentSound.playerStatus = 'pause'
          }
          const component = get(vm.$refs[rowPath], [0])
          if (!component || component.$el === exclude) {
            return
          }
          component.$el.style.display = 'none'
          component.$el.style.marginBottom = ''
        })
      }
      hideAllSoundPlayer()
      $$el.find(soundSelector).on('click', function () {
        if (this.dataset.soundFileId) {
          vm.$store.commit('MAINSOUNDPLAYER_SET_TMP_SOUND', {
            url: store.get('AppFile', this.dataset.soundFileId).download,
            name: store.get('AppFile', this.dataset.soundFileId).name,
          })
        }
      })
      $$el
        .find('div.statement span.pi-exercice, .pi-wysiwyg-table table .exercice, .pi-interactive-image .exercice')
        .each((index, spanExo) => {
          const targetClassCopy = `div.ex[data-value="${spanExo.dataset.value}"]`
          const $spanExo = $(spanExo)
          // FIX old version
          if (isUndefined(spanExo.dataset.type)) {
            if (spanExo.dataset.is !== undefined) {
              spanExo.dataset.type = 'drag-n-drop'
            } else {
              spanExo.dataset.type = get(this, `${spanExo.dataset.value}.type`)
            }
          }
          if (spanExo.dataset.is === 'Glisser/déposer') {
            if (includes(spanExo.dataset.value, 'target')) {
              spanExo.dataset.is = 'question'
            }
            if (includes(spanExo.dataset.value, 'choice')) {
              spanExo.dataset.is = 'answer'
            }
          }
          // end FIX
          if (spanExo.dataset.type === 'combined-choice') {
            // COMBINED CHOICE
            $spanExo.replaceWith($(`${targetClassCopy} .el-select`))
          } else if (spanExo.dataset.type === 'free-answer') {
            // FREE ANSWER
            $spanExo.replaceWith($(`${targetClassCopy} .interactif`))
          } else if (spanExo.dataset.type === 'drag-n-drop') {
            // DRAG & DROP
            if (spanExo.dataset.is === 'answer') {
              // FIX dans les tableaux les données enregistrées ne contiennent ni message / ni value
              let selector = spanExo.dataset.value
              selector = endsWith(selector, '.value') ? selector : `${selector}.value`
              $spanExo.replaceWith($(`div.ex .answer[data-value="${selector}"] .interactif`)[0])
            } else if (spanExo.dataset.is === 'question') {
              // FIX dans les tableaux les données enregistrées ne contiennent ni message / ni value
              let selector = spanExo.dataset.value
              selector = endsWith(selector, '.message') ? selector : `${selector}.message`
              $spanExo.replaceWith($(`div.ex .question[data-value="${selector}"] .interactif`)[0])
            }
          } else if (spanExo.dataset.type === 'pick-words') {
            // PICK WORDS
            $spanExo.replaceWith(
              $(`<span class="pi-exercice pick-words pick-words-unselected">${spanExo.innerHTML}</span>`),
            )
          }
        })
      if (this.hasClickWord) {
        // activation des données présaisies (statement)
        $statement.find('span.pick-words').each((index, node) => {
          const $node = $(node)
          const text = $node.text()
          $node.attr('data-pw', text)
          if (includes(vm.wordPicked, text)) {
            $node.removeClass('pick-words-unselected').addClass('pick-words-selected')
          }
        })
        // activation des données présaisies (table)
        $$el
          .find('.pi-wysiwyg-table table')
          .find('.text-cell')
          .addClass('pick-words pi-exercice')
          .addClass('pick-words-unselected')
          .each((index, node) => {
            const $node = $(node)
            if (includes(vm.wordPicked, $node.attr('data-loc'))) {
              $node.removeClass('pick-words-unselected').addClass('pick-words-selected')
            }
          })
      }
      // ON SUPPRIME LES EXOS VIDES
      $('.ex').each((index, el) => {
        const $el = $(el)
        if ($el.find('.interactif').length === 0) {
          $el.hide()
        }
      })
      // ON SUPPRIME LES DRAG & DROP VIDES
      $('.pi-drag-n-drop .answer, .pi-drag-n-drop .question ').each((index, el) => {
        const $el = $(el)
        if ($el.find('.interactif').length === 0) {
          $el.hide()
        }
      })
      // PICK WORDS Comportements
      if (this.hasClickWord && !this.isCorrectionMode) {
        const $table = $$el.find('.pi-wysiwyg-table table')
        $table.find('.text-cell.pick-words').click(function () {
          $(this).toggleClass('pick-words-unselected').toggleClass('pick-words-selected')
          updatePickWords()
        })
        $table
          .on('mouseover', '.pick-words-unselected', function () {
            const $this = $(this)
            $this.addClass('hover')
          })
          .on('mouseout', '.pick-words.hover', function () {
            const $this = $(this)
            $this.removeClass('hover')
          })

        $statement
          .on('mouseover', '.pick-words-unselected', function () {
            const $this = $(this)
            const $all = $statement.find(`.pick-words[data-pw="${$this.attr('data-pw')}"]`)
            $all.addClass('hover')
          })
          .on('mouseout', '.pick-words.hover', function () {
            const $this = $(this)
            const $all = $statement.find(`.pick-words[data-pw="${$this.attr('data-pw')}"]`)
            $all.removeClass('hover')
          })
        $statement.find('.pick-words').click(function () {
          const $this = $(this)
          const $all = $statement.find(`.pick-words[data-pw="${$this.attr('data-pw')}"]`)
          if ($this.is('.pick-words-selected')) {
            $all.removeClass('pick-words-selected').addClass('pick-words-unselected')
          } else {
            $all.removeClass('pick-words-unselected').addClass('pick-words-selected')
          }
          updatePickWords()
        })
      }
    },
    setCorrection() {
      const $$el = $(this.$el)
      $$el.find('.pi-content').each((index, node) => {
        const nodeV = node.dataset.value
        const goodAnswers = this.correctAnswer[nodeV]

        if (includes(node.classList, 'pi-pick-words')) {
          $$el.find('.pi-exercice.pick-words').each((index, word) => {
            const goodAnswersParsed = map(goodAnswers.answer.all, (el) => this.parseShortCodes(el))
            if (
              includes(goodAnswersParsed, this._get(word, 'dataset.pw')) ||
              includes(goodAnswers.answer.all, this._get(word, 'dataset.loc'))
            ) {
              $(word).addClass('expectedAnswer')
              if ($(word).hasClass('pick-words-selected')) {
                $(word).addClass('goodAnswer')
              }
            } else if ($(word).hasClass('pick-words-selected')) {
              $(word).addClass('wrongAnswer')
            }
          })
        }
      })
    },
    tincanListeners(message) {
      if (message.data === 'action:next') {
        this.page.lesson
          .emptyPatchCurrentPage(this.page.id, this.inputs)
          .catch(() => {})
          .then(() => {
            this.closeIframeInFullscreen()
            this.$emit('next')
          })
      } else if (message.data === 'action:close') {
        this.page.lesson
          .emptyPatchCurrentPage(this.page.id, this.inputs)
          .catch(() => {})
          .then(() => {
            this.closeIframeInFullscreen()
            this.backToHome({ clear: false })
          })
      } else if (message.data.action === 'action:custom') {
        if (!this.isCorrectionMode && !this.isNodeMode) {
          this.page.lesson.sendCustomTincanScore(this.page.id, message.data.score).catch(console.error)
        }
      }
    },
  },
}
</script>
